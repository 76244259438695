<template>
	<div class="cover">
		<div class="cover-content">
			<div class="cover-msg">
				<h1 v-text="pageMsg.title"></h1>
				<div v-if='pageMsg.article'>
					<p><span>发表于：</span><span v-text="$moment(pageMsg.article.createTime).format('YYYY-MM-DD')"></span></p>
					<p><span>更新于：</span><span v-text="$moment(pageMsg.article.updateTime).format('YYYY-MM-DD')"></span></p>
					<p><span>分类：</span><span v-text='pageMsg.article.type'></span></p>
					<p><span>浏览量：</span><span v-text='pageMsg.article.views'></span></p>
					<p><span>评论数：</span><span v-text='pageMsg.article.countsComment'></span></p>
				</div>
			</div>
		</div>
		<div class="backImg">
			<img v-lazy="pageMsg.imgUrl" alt="imgUrl">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TopCover',
		data() {
			return {
				page: []
			}
		},
		props: ['pageMsg'],
	}
</script>

<style scoped>
.cover{
	position: relative;
	width: 100%;
	height: 540px;
	top: -60px;
	margin: 0 auto;
	margin-bottom: 3em;
	border-radius: 0 0 7em 7em;
	overflow: hidden;
	z-index: 0;
}
.cover img{
	width: 100%;
	height: 540px;
	filter: blur(1px) brightness(0.8);
	object-fit: cover;
	transition: all .5s ease-in-out;
}
.cover img:hover{
	filter: blur(0px) brightness(0.9);
	transform: scale(1.05);
}
.cover-content{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}
.cover-content .cover-msg{
	position: absolute;
	color: white;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0em;
	margin: auto;
}
.cover-content:hover  +  .backImg  > img{
	filter: blur(0px) brightness(0.9);
	transform: scale(1.05);
}
.cover-content h1{
	color: white;
}
.cover-msg div{
	margin-top: 2em;
}
.cover-msg div p{
	display: inline-block;
	margin-right: 1em;
	border-right: 1px solid white;
	padding-right: .5em;
}
.darkApp .cover img{
	filter: blur(1px) brightness(0.6);
}
.darkApp .cover img:hover{
	filter: blur(0px) brightness(0.7);
}
.darkApp .cover-content:hover  +  .backImg  > img{
	filter: blur(0px) brightness(0.7);
}
@media screen and (max-width: 770px) {
	.cover{
		height: 300px;
		border-radius: unset;
		z-index: -1;
	}
	.cover img{
		height: 300px;
	}
	.cover-msg div{
		margin-top: 0;
	}
}
</style>
